.orders-table {
  width: 100%;
  height: 70vh;
}

[dir="rtl"] .orders-table div {
  font-family: "Montserrat" !important;
}
[dir="rtl"] .ag-root span {
  font-family: "Montserrat" !important;
}
[dir="rtl"] .orders-table time {
  font-family: "Montserrat" !important;
}

.ag-header {
  background-color: #f1faee !important;
}
.ag-root-wrapper {
  /* border: 1px!important; */
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: #f1faee !important;
}
.ag-header-cell-label {
  justify-content: center;
}
.test-group {
  overflow: scroll;
}
.popover-title,
.popover-title-comment {
  background-color: #1d3557 !important;
  color: #f1faee !important;
  font-size: 13px !important;
}
.popover-body,
.popover-body-comment {
  background-color: #f1faee !important;
  font-size: 12px !important;
}
.items {
  font-weight: 600 !important;
  font-size: 12px !important;
}
