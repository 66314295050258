.dropdown-toggle::after {
  display: none !important;
}
.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
}
.dropdown-toggle:focus {
  box-shadow: none !important;
}
.icon-style {
  color: whitesmoke !important;
  font-size: 19px !important;
}
.menu-style {
  background-color: #1d3557 !important;
}

.dropdown-item {
  color: whitesmoke !important;
  font-weight: 800 !important;
  font-size: 11px!important;
}
.dropdown-item:hover{
    background-color: #10213a!important;
}
