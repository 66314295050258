.orders-table {
  min-width: 100%;
  height: 70vh;
}
.download-btn{
  text-decoration: none!important;
  font-weight: 500!important;
  color: whitesmoke!important;
}
.download-btn-link{
  text-decoration: none!important;
  color: white!important;
}
.not-allowed{
  color: #DC3545;
}

[dir = rtl] .orders-table div{
  font-family: "Montserrat"!important;
}
[dir = rtl] .ag-root span{
  font-family: "Montserrat"!important;
}
[dir = rtl] .orders-table time{
  font-family: "Montserrat"!important;
}