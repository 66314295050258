body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "AraAqeeq-ExtraBold";
  src: url("./fonts/AraAqeeq-ExtraBold.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat";
}
[dir = rtl] *{
  box-sizing: border-box;
  font-family: "AraAqeeq-ExtraBold";
}




:root {
  --main-color: #bf1a16;
  --secondary-color: #e3a130;
  --dark-main-color: #830b0a;
  --light-secondary-color: #fdd75d;
}
