.order {
  min-height: 100vh;
  width: 100%;
  background-color: whitesmoke;
  position: relative;
}
.orders-parent {
  justify-content: space-between;
}
/* .orders-table-parent{
    height: 70vh;
    background-color: #006E6C;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  } */
.orders-filter {
  height: 25vh;
  background-color: #006e6c;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.data-filter,
.patient-contact-filter {
  background-color: #f1faee;
  border: 1px solid gainsboro;
}
.data-filter-title,
.patient-contact-title {
  text-align: left !important;
  color: var(--main-color) !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  font-style: italic !important;
}
.text-error {
  color: #dc3545 !important;
  font-weight: 500 !important;
  font-size: 0.75em !important;
}
.search-title {
  background-color: #1d3557;
  color: whitesmoke !important;
  font-style: italic !important;
}
.search-title-text {
  font-size: 17px !important;
  font-weight: 400 !important;
}
.search-body {
  background-color: #f1faee;
}
.font-styl{
  font-weight: 700!important;
  font-size: 15px!important;
}
.phone-addon{
  border: 1px solid gainsboro!important;
  background-color: #f1faee!important;
}
.input-text:focus{
  outline: none!important;
  box-shadow: none!important;
}