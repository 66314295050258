.text-error {
    color: #dc3545 !important;
    font-weight: 500 !important;
    font-size: 0.75em !important;
  }
  .search-title {
    background-color: #1d3557;
    color: whitesmoke !important;
    font-style: italic !important;
  }
  .search-title-text {
    font-size: 17px !important;
    font-weight: 400 !important;
  }
  .search-body {
    background-color: #f1faee;
  }
  .input-text:focus{
    outline: none!important;
    box-shadow: none!important;
  }
  [dir = rtl] #comment{
    font-size: 16px!important;
  }
  .input-label{
    font-weight: 600!important;
}